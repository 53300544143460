import axios from 'axios';
import { BACKEND_URL } from '../constants';
import Cookies from 'js-cookie';

const instance = axios.create({
    baseURL: BACKEND_URL,
    withCredentials: true,
});

instance.interceptors.response.use(response => response, error => {
    console.error('API Error:', error);
    return Promise.reject(error);
});


const getSignedUrl = () => {
    return instance.post(`${BACKEND_URL}/signed_url`);
};

const createSampleImagePrompt = (promptData) => {
    return instance.post(`${BACKEND_URL}/prompts`, promptData);
};

const uploadFileToS3 = (uploadUrl, file, onUploadProgress) => {
    return instance.put(uploadUrl, file, {
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        onUploadProgress,
    });
};

const createProject = (projectData) => {
    return instance.post(`${BACKEND_URL}/projects`, projectData);
};

const createImageProject = (projectData) => {
    return instance.post(`${BACKEND_URL}/images`, projectData);
};

const createImage = (imageData) => {
    return instance.post(`${BACKEND_URL}/images`, imageData);
};

const createMetadata = (data, onSuccess) => {
    return instance.post(`${BACKEND_URL}/metadata`, data)
        .then(response => {
            onSuccess(response.data.metadata);
            return response;
        })
        .catch(error => {
            throw error;
        });
};


const createPrompt = (data) => {
    return instance.post(`${BACKEND_URL}/prompts`, data);
};

const logout = () => {
    return instance.post(`${BACKEND_URL}/logout`);
};

const isAuthenticated = () => {
    return instance.get(`${BACKEND_URL}/is_authenticated`);
};


const fetchColorMappings = () => {
    return instance.get(`${BACKEND_URL}/color_mappings`);
};

const starProject = (projectId) => {
    return instance.post(`${BACKEND_URL}/star/${projectId}`, {});
};

const unstarProject = (projectId) => {
    return instance.delete(`${BACKEND_URL}/star/${projectId}`, {});
};

const getProjects = (starred, allProjects) => {
    if (starred === undefined) {
        starred = false;
    }
    let allProjectsParam = "";
    if (allProjects) {
        allProjectsParam = "&all_projects=true";
    }

    return instance.get(`${BACKEND_URL}/projects?starred=${starred}${allProjectsParam}`);
};

const deleteProject = (projectId) => {
    return instance.delete(`${BACKEND_URL}/projects/${projectId}`);
};

const getProjectDetails = (projectId) => {
    return instance.get(`${BACKEND_URL}/projects?id=${projectId}`);
};


const patchProject = (projectId, patchData) => {
    return instance.patch(`${BACKEND_URL}/projects/${projectId}`, patchData);
};
const deleteTag = (tagId) => {
    return instance.delete(`${BACKEND_URL}/tags/${tagId}`);
};

export const api = {
    getProjects,
    deleteProject,
    getProjectDetails,
    patchProject,
    isAuthenticated,
    logout,
    getSignedUrl,
    uploadFileToS3,
    createProject,
    fetchColorMappings,
    starProject,
    unstarProject,
    createImage,
    createPrompt,
    createMetadata,
    createSampleImagePrompt,
    deleteTag,
    createImageProject

};
