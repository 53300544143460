import { default as React, useState, useRef, useEffect } from 'react';
import './ImageNewAI.css';
import { api } from '../services/api';
import Login from './Login';



const ImageNewAI = (isAuthenticatedLocal) => {
    const STAGES = {
        IMAGE_SOURCE: 'image:source',
        IMAGE_UPLOAD: 'image:upload',
        IMAGE_DESCRIBE: 'image:describe',
        IMAGE_DESCRIBE_CREATED: 'image:describe_created',
        IMAGE_UPLOADED: 'image:uploaded',
        PARAMS_DISPLAY: 'params:display',
        PROJECT_CREATED: 'project_created',

    };
    const [projectName, setProjectName] = useState('');
    const [projectTags, setProjectTags] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [imageCreationOption, setImageCreationOption] = useState('');
    const [stage, setStage] = useState(STAGES.IMAGE_SOURCE);
    const [s3FileUrl, setS3FileUrl] = useState(null);
    const [uniqueId, setUniqueId] = useState(null);
    const [elements, setElements] = useState('');
    const [imageRawPrompt, setImageRawPrompt] = useState('');
    const [projectImageUrl, setProjectImageUrl] = useState('');
    const [progress, setProgress] = useState(0);
    const [isProgressStarted, setIsProgressStarted] = useState(false);
    const [isAdvancedMode, setIsAdvancedMode] = useState(false);
    const [complexity, setComplexity] = useState('normal');
    const intervalRef = useRef(null);


    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleImageIsOK = async () => {
        setStage(STAGES.PARAMS_DISPLAY);
    }
    const handleUploadAgain = async () => {
        setStage(STAGES.IMAGE_UPLOAD);
    }
    const handleDescribeAgain = async () => {
        setStage(STAGES.IMAGE_DESCRIBE);
    }
    const handleDescriptionChange = (event) => {
        setElements(event.target.value);
    };
    const handleRawPromptChange = (event) => {
        setImageRawPrompt(event.target.value);
    }
    const handleViewProject = () => {
        window.location.href = `/#/image-details/${uniqueId}`;
    }

    const handleSetAdvancedMode = () => {
        setIsAdvancedMode(true);
    }
    const handleSetBasicMode = () => {
        setIsAdvancedMode(false);
    }

    const handleSurprizeMe = async () => {
        if (isAdvancedMode) {
            const promptData = {
                elements: "",
                request_type: "SURPRIZE_ME_ADVANCED",
                complexity: complexity
            }
            setImageRawPrompt("");
            setNotificationMessage("Creating a surprize image prompt for you...");
            startProgress();
            const response = await api.createPrompt(promptData);
            if (response.status != 200) {
                setNotificationMessage('Something went wrong. Please try again later.');
                setIsProgressStarted(false);
            }
            setImageRawPrompt(response.data.prompt);
            setIsProgressStarted(false);
            setNotificationMessage("");
        }
        if (!isAdvancedMode) {
            const promptData = {
                elements: "",
                request_type: "SURPRIZE_ME_BASIC",
                complexity: complexity
            }
            setImageRawPrompt("");
            setNotificationMessage("Creating a surprize image prompt for you...");
            startProgress();
            const response = await api.createPrompt(promptData);
            if (response.status != 200) {
                setNotificationMessage('Something went wrong. Please try again later.');
                setIsProgressStarted(false);
            }
            setElements(response.data.prompt);
            setIsProgressStarted(false);
            setNotificationMessage("");
        }
    }

    function handleImageTypeChange(event) {
        setComplexity(event.target.value);
    }

    const startProgress = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        setProgress(0);

        setIsProgressStarted(true);
        intervalRef.current = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 99.9) {
                    clearInterval(intervalRef.current);
                    setIsProgressStarted(false);
                    return 99.9;
                }
                const increment = 0.6 * (prevProgress < 30 ? 1 : 0.1 * (100 - prevProgress));
                return prevProgress + increment;
            });
        }, 400);
    };

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isProgressStarted) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    }, [isProgressStarted]);


    const handleCreateProject = async () => {
        if (!projectName) {
            setNotificationMessage("Please provide a name for the image.");
            return;
        }
        setNotificationMessage("Creating image, please wait...")
        startProgress();

        const projectData = {
            kind: 1,
            unique_id: uniqueId,
            project_name: projectName,
            tags: projectTags
        };
        const response = await api.createProject(projectData);
        setProjectImageUrl(response.data.s3_image_url);
        setStage(STAGES.PROJECT_CREATED);
        setNotificationMessage("Project created.");
        setIsProgressStarted(false);
        handleViewProject();
    }

    const handleRestartAgain = async () => {
        setNotificationMessage("");
        setStage(STAGES.IMAGE_SOURCE);
        setImageCreationOption(null);

    }

    const handleImageSourceOptionChange = async (event) => {
        setImageCreationOption(event.target.value);
        if (event.target.value == "upload") {
            setStage(STAGES.IMAGE_UPLOAD);
        }
        if (event.target.value == "describe") {
            setStage(STAGES.IMAGE_DESCRIBE);
        }
    };


    const getImgClassNameForStage = (stage) => {
        switch (stage) {
            case STAGES.IMAGE_UPLOADED:
                return "fullImage";
            case STAGES.IMAGE_DESCRIBE_CREATED:
                return "fullImage";
            case STAGES.PARAMS_DISPLAY:
                return "halfImage";
        }
    };

    const handleUpload = async () => {
        try {
            setIsUploading(true);
            setIsProgressStarted(true);
            setNotificationMessage("Uploading, please wait...")
            setProgress(0);

            const signedUrlResponse = await api.getSignedUrl();
            const { url: uploadUrl, id: uniqueId, s3_file_url: s3FileUrlFromServer } = signedUrlResponse.data;
            setUniqueId(uniqueId);

            const uploadProgress = (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
                console.log(`Uploading... ${percentCompleted}%`);
            };
            const response = await api.uploadFileToS3(uploadUrl, selectedFile, uploadProgress);
            setS3FileUrl(s3FileUrlFromServer);
            setStage(STAGES.PARAMS_DISPLAY);
            setNotificationMessage("")

        } catch (error) {
            console.error('Error during the upload process', error);
            setNotificationMessage('Error during the upload process');
        } finally {
            setIsUploading(false);
            setIsProgressStarted(false);
        }
    };


    const handleCreateImage = async () => {
        try {
            setIsUploading(true);
            startProgress();
            setNotificationMessage("Creating a prompt for your image, please wait...")

            if (isAdvancedMode) {
                api.createMetadata({ image_raw_prompt: imageRawPrompt },
                    (metadata) => {
                        setProjectName(metadata.title);
                        setProjectTags(metadata.tags);
                    });
                startProgress();
                setIsProgressStarted(true);
                setIsUploading(true);
                await createAIImage(imageRawPrompt);
            }
            if (!isAdvancedMode) {
                const promptData = {
                    elements: elements,
                    request_type: "CREATE_PROMPT_IMAGE",
                    complexity: complexity
                }
                setImageRawPrompt("");
                const response = await api.createPrompt(promptData);
                if (response.status != 200) {
                    setNotificationMessage('Something went wrong. Please try again later.');
                    setIsProgressStarted(false);
                }
                setImageRawPrompt(response.data.prompt);
                setIsProgressStarted(false);
                setNotificationMessage("");
                api.createMetadata({ image_raw_prompt: response.data.prompt },
                    (metadata) => {
                        setProjectName(metadata.title);
                        setProjectTags(metadata.tags);
                    });
                setIsProgressStarted(true);
                setIsUploading(true);
                createAIImage(response.data.prompt);

            }
        } catch (error) {
            console.error('Error during the image creation process', error);
            setNotificationMessage('Something went wrong. Please try again later.');
        } finally {
            setIsUploading(false);

        }
    }

    const createAIImage = async (imageRawPromptForServer) => {
        try {
            setIsUploading(true);
            setNotificationMessage("Creating image, please wait...")

            const imageData = {
                unique_id: uniqueId,
                image_raw_prompt: imageRawPromptForServer,
            };

            const response = await api.createImage(imageData);
            if (response.status != 200) {
                setNotificationMessage('Something went wrong. Please try again later.');
                setIsProgressStarted(false);
            }
            const s3_image_url = response.data.image_url[0];
            const raw_prompt = response.data.prompt;
            const uniqueIdFromServer = response.data.unique_id;
            setS3FileUrl(s3_image_url);
            setImageRawPrompt(raw_prompt);
            setUniqueId(uniqueIdFromServer);
            setStage(STAGES.IMAGE_DESCRIBE_CREATED);
            setIsProgressStarted(false);
            setNotificationMessage("");
        } catch (error) {
            console.error('Error during the image creation process', error);
            setNotificationMessage('Something went wrong. Please try again later.');
        } finally {
            setIsUploading(false);

        }
    };


    const mustAuthenticateHint = () => (
        <div className='login_hint'>
            <div className="alert alert-warning ml-5 mr-5 mt-5" role="alert">
                To create a new image you have to login first.
                This is needed so that we can associate the new image with your account.
            </div>
            <Login></Login>
        </div >
    )

    const createImageViaUploadScreen = () => {
        return (<>
            {stage == STAGES.IMAGE_UPLOAD && (
                <>
                    {(!isProgressStarted) && (
                        <>
                            <div className="mb-3">
                                <label htmlFor="formFile" className="form-label">Upload your image</label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    onChange={handleFileChange}
                                    disabled={isUploading || stage != STAGES.IMAGE_UPLOAD}
                                />
                            </div>
                        </>)}
                    <div className="text-end">
                        <button className="btn btn-secondary" onClick={handleRestartAgain}>
                            Start Over
                        </button>
                        <button className="btn btn-primary" onClick={handleUpload} disabled={isUploading || stage != STAGES.IMAGE_UPLOAD}>
                            {isUploading ? 'Uploading, please wait...' : 'Upload'}
                        </button>
                    </div>
                </>
            )
            }

            {(stage == STAGES.IMAGE_UPLOADED || stage == STAGES.PARAMS_DISPLAY) &&
                <>
                    <div style={{ textAlign: "center" }}>
                        <img className={getImgClassNameForStage(stage)} id="imgUploaded" src={s3FileUrl} />
                    </div>
                    {stage == STAGES.IMAGE_UPLOADED && (
                        <div className='text-end'>
                            <button className="btn btn-secondary" onClick={handleUploadAgain}>
                                Upload another image.
                            </button>
                            <button className="btn btn-primary" onClick={handleImageIsOK}>
                                Looks good, save it.
                            </button>
                        </div>
                    )
                    }

                </>
            }
        </>)
    }


    const aiImageCreateDescribeStageinputHints = () => {
        return (<>
            <div>
                <label className="form-check-label" htmlFor="uploadImage">
                    {
                        (!isAdvancedMode) && (
                            <div className='hint' style={{ display: 'flex', alignItems: 'center' }}>
                                <img className="imgInputType" src='ai-assisted.jpg' style={{ marginRight: '20px' }} alt='AI Assisted Image'></img>
                                <div style={{ textAlign: 'left' }}>
                                    <strong>Basic Mode (Recommended)</strong><br />
                                    You suggest some elements you want in your image. AI completes the image for you.
                                </div>
                            </div>
                        )}
                    {
                        (isAdvancedMode) && (
                            <div className='hint' style={{ display: 'flex', alignItems: 'center' }}>
                                <img className="imgInputType" src='advanced-no-ai.jpg' style={{ marginRight: '20px' }} alt='AI Assisted Image'></img>
                                <div style={{ textAlign: 'left' }}>
                                    <strong>Advanced Mode</strong><br />
                                    You are in full control of everything. AI does not enhance your image description.
                                </div>
                            </div>
                        )}

                </label></div></>)
    }

    const aiImageCreateDescribeStagePromptInput = () => {
        return (<>

            <div className="container-input-label">
                <label className="form-input-label" htmlFor="uploadImage">
                    <div style={{ marginBottom: "15px" }}>
                        <input
                            type="radio"
                            style={{ marginRight: "5px" }}
                            id="veryBasic"
                            name="imageType"
                            value="veryBasic"
                            onChange={handleImageTypeChange}
                            checked={complexity === 'veryBasic'}

                        />
                        <label htmlFor="veryBasic" style={{ marginRight: "15px" }}>Simple Two-Color Pattern</label>
                        <input
                            type="radio"
                            id="normal"
                            style={{ marginRight: "5px" }}
                            name="imageType"
                            value="normal"
                            onChange={handleImageTypeChange}
                            checked={complexity === 'normal'}

                        />
                        <label htmlFor="normal" style={{ marginRight: "15px" }}>Detailed Multicolor Design</label>
                    </div>
                    <div>
                        {(!isAdvancedMode) && (<>What elements should your image contain?</>)}
                        {(isAdvancedMode) && (<>Image generation prompt (raw):</>)}
                    </div>
                </label>

                <button className="btn btn-success" id="surprizeMe" onClick={handleSurprizeMe}>
                    Surprise me!
                </button>
            </div>

            {((!isAdvancedMode)) && (
                <>



                    <textarea
                        id="imageDescription"
                        className="form-control"
                        placeholder="cute pink cats eating pasta on a blue plate"
                        rows="3"
                        onChange={handleDescriptionChange}
                        disabled={isUploading}
                        value={elements}
                        style={{ width: '100%' }}
                    ></textarea>



                </>
            )}

            {(isAdvancedMode) && (
                <>
                    <textarea
                        id="imageRawPrompt"
                        className="form-control"
                        placeholder=""
                        rows="9"
                        onChange={handleRawPromptChange}
                        disabled={isUploading}
                        value={imageRawPrompt}
                    ></textarea></>
            )}
        </>)
    }

    const aiImageCreateDescribeStageButtons = () => {
        return (<>
            <div className="text-end">
                {(!isAdvancedMode) && (<button
                    className={`btn btn-link gray`}
                    onClick={handleSetAdvancedMode}
                >
                    Switch to Advanced Mode
                </button>)}
                {(isAdvancedMode) && (<button
                    className={`btn btn-link gray`}
                    onClick={handleSetBasicMode}
                >
                    Switch to Basic Mode
                </button>)}
                <button className="btn btn-secondary" onClick={handleRestartAgain}>
                    Start Over
                </button>
                <button className="btn btn-primary" onClick={handleCreateImage} disabled={isUploading || isProgressStarted}>
                    {isUploading || isProgressStarted ? 'Creating image, please wait...' : 'Create Image'}
                </button>

            </div>
        </>)
    }

    const createImageViaAI = () => {
        return (<>

            {/* {((stage == STAGES.IMAGE_DESCRIBE && isProgressStarted) || stage == STAGES.IMAGE_DESCRIBE_CREATED || stage == STAGES.PARAMS_DISPLAY) &&
                <>
                    <div id="raw_prompt">
                        {imageRawPrompt}
                    </div>
                </>
            } */}

            {(stage == STAGES.IMAGE_DESCRIBE) && (
                <>
                    {(!isProgressStarted) && (
                        <>
                            {aiImageCreateDescribeStageinputHints()}
                            {aiImageCreateDescribeStagePromptInput()}
                        </>)}
                    {aiImageCreateDescribeStageButtons()}
                </>
            )
            }


            {
                (stage == STAGES.IMAGE_DESCRIBE_CREATED || stage == STAGES.PARAMS_DISPLAY) &&
                <>
                    <div style={{ textAlign: "center" }}>
                        <img className={getImgClassNameForStage(stage)} id="imgUploaded" src={s3FileUrl} />
                    </div>
                    {stage == STAGES.IMAGE_DESCRIBE_CREATED && (
                        <div className='mt-4 text-end'>
                            <button className="btn btn-secondary" onClick={handleDescribeAgain}>
                                I don't like it. Let's try again!
                            </button>
                            <button className="btn btn-primary" onClick={handleImageIsOK}>
                                Looks good. Save it.
                            </button>
                        </div>
                    )
                    }
                </>
            }
        </>)
    }

    const selectImageGenerationType = () => {
        return (
            (stage == STAGES.IMAGE_SOURCE) && (
                <>
                    <h5 className="card-title">How do you want to create the image?</h5>
                    <form>
                        <div className="mb-3">
                            <input
                                type="radio"
                                className="btn-check"
                                name="imageCreationType"
                                id="uploadImage"
                                autoComplete='off'
                                value="upload"
                                checked={imageCreationOption === 'upload'}
                                onChange={handleImageSourceOptionChange}
                            />
                            <label className="btn btn-light  imgSourceClass" htmlFor="uploadImage">
                                <img className="choiceImg" src='upload-img.jpg' alt="Create Image via AI" style={{ marginRight: "20px", verticalAlign: "middle" }}></img>
                                Upload my own image
                            </label>

                            <input
                                type="radio"
                                className="btn-check"
                                name="imageCreationType"
                                id="describeImage"
                                autoComplete='off'
                                value="describe"
                                checked={imageCreationOption === 'describe'}
                                onChange={handleImageSourceOptionChange}
                            />
                            <label className="btn btn-light imgSourceClass" htmlFor="describeImage">
                                <img className="choiceImg" src='create-via-ai.jpg' alt="Create Image via AI" style={{ marginRight: "20px", verticalAlign: "middle" }}></img>
                                Create an image via AI
                            </label>

                        </div>
                    </form></>))
    }

    const progressBar = () => {
        return (
            <>
                <div className="progress mb-4 mt-4" style={{ width: '100%', height: '10px' }}>
                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: 'blue' }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                </div>


            </>
        )
    }

    const imageGenerationStage = () => {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-10">
                        <div className="card" style={{ padding: "30px" }}>
                            <div className="card-body">

                                {(stage == STAGES.IMAGE_SOURCE || stage == STAGES.IMAGE_UPLOAD || stage == STAGES.IMAGE_UPLOADED || stage == STAGES.IMAGE_DESCRIBE || stage == STAGES.IMAGE_DESCRIBE_CREATED) &&
                                    (
                                        selectImageGenerationType())
                                }

                                {(isProgressStarted || isUploading) && progressBar()}
                                {notificationMessage && (
                                    <div className="alert alert-info mt-3">
                                        {notificationMessage}
                                    </div>
                                )}

                                {imageCreationOption === 'upload' && (
                                    createImageViaUploadScreen()
                                )}

                                {imageCreationOption === 'describe' && (
                                    createImageViaAI()
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    };



    const patternParamsStage = () => (

        <div className="container">
            <div className="row">
                <div className="col-md-10">
                    <div className="card">
                        <div className="card-body">
                            {(!isProgressStarted) && (
                                <>
                                    <div className="form-group mb-3">
                                        <label htmlFor="projectName">Image Name</label>
                                        <input
                                            type="text"
                                            id="projectName"
                                            className="form-control"
                                            placeholder="Enter image name"
                                            value={projectName}
                                            onChange={(e) => setProjectName(e.target.value)}
                                        />
                                    </div>

                                </>)}

                            <div className="text-end">
                                <button className="btn btn-secondary" onClick={handleRestartAgain}>
                                    Start Again
                                </button>
                                <button className="btn btn-primary" onClick={handleCreateProject} disabled={isProgressStarted}>
                                    {isProgressStarted ? 'Processing, please wait...' : 'Save Image'}
                                </button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const projectCreatedStage = () => {
        const projectImageWithTimestamp = `${projectImageUrl}?${new Date().getTime()}`;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-body" style={{ textAlign: "center" }}>

                                <img className='fullProjectImage' src={projectImageWithTimestamp}></img>

                                <div className='mt-3 text-end'>
                                    <button className="btn btn-primary" onClick={handleViewProject}>
                                        Save Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div >
        );
    }

    const authenticatedContent = () => (
        <div id="mainDivNew">
            {imageGenerationStage()}
            {stage.startsWith("params") && patternParamsStage()}
            {stage == STAGES.PROJECT_CREATED && projectCreatedStage()}
        </div>
    )

    if (isAuthenticatedLocal.isAuthenticatedLocal) {
        return authenticatedContent();
    } else {
        return authenticatedContent();
        return mustAuthenticateHint();
    }
};

export default ImageNewAI;
