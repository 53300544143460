import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../services/api';
import './ProjectImageDetail.css';
import ProjectSetup from '../components/ProjectSetup';
import PatternEditor from '../components/PatternEditor';
import Login from './Login';


const ProjectImageDetail = ({ setHeaderVisible, isAuthenticatedLocal }) => {
    let { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [imageTimestamp, setImageTimestamp] = useState('');
    const imageRef = useRef(null);

    const forceReloadImage = () => {
        setImageTimestamp(new Date().getTime());
    }

    const handleCreatePattern = () => {
        window.location.href = `/#/from-image/${projectId}`;
    }


    useEffect(() => {
        if (projectId) {
            fetchProjectDetails();
        } else {
            setError('No projectId specified.');
        }
        forceReloadImage();

    }, [projectId]);


    const fetchProjectDetails = async () => {
        setLoading(true);
        try {
            const response = await api.getProjectDetails(projectId);
            setProject(response.data);
            setError('');
        } catch (error) {
            console.error('Error fetching project details:', error);
            setError('Error fetching project details. Please try again later.');
        } finally {
            setLoading(false);
        }
    };



    if (error) {
        return <div className="alert alert-danger" role="alert">{error}</div>;
    }


    return (
        <div style={{ marginTop: "90px", backgroundColor: "#EEE" }}>

            {loading ? (
                <div style={{ marginLeft: '40px' }}>Loading...</div>
            ) : (
                <div>
                    {project ? (
                        <div>
                            <div className="detailsProjectHeader">
                                <span className="detailsProjectTitle">{project.project_name}</span>
                            </div>

                            <div>
                                <div id="detailsImageContainer" ref={imageRef}>
                                    <div>
                                        <button onClick={handleCreatePattern} style={{ fontSize: "120%", marginBottom: "20px" }} className='btn btn-primary'>Create pattern from this image</button>
                                    </div>
                                    <div>
                                        <img src={`${project.raw_original_image_path}?${imageTimestamp}`} alt="Image" id="detailsImage" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>Project not found</div>
                    )}
                </div>
            )
            }


        </div >



    );
};

export default ProjectImageDetail;
