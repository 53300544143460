
const Profile = () => {

    return (
        <div>
            <div className="container mt-5" style={{ minHeight: '100vh' }}>
                <div className="row">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-header">
                                <h3>Profile</h3>
                            </div>
                            This is my profile page.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
