import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Projects from './pages/Projects';
import ProjectPatternDetail from './pages/ProjectPatternDetail';
import ProjectImageDetail from './pages/ProjectImageDetail';
import About from './pages/About';
import Header from './components/Header';
import Login from './pages/Login';
import Profile from './pages/Profile';
import ProjectNew from './pages/ProjectNew'
import ImageNewAI from './pages/ImageNewAI'
import ProjectFromImage from './pages/ProjectFromImage'
import PatternNew from './pages/PatternNew'

import { api } from './services/api';

import React, { useState, useEffect } from 'react';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const [isAuthenticatedLocal, setIsAuthenticatedLocal] = useState(null);
  const [isAdminMode, setIsAdminMode] = useState(false);
  const [usernameLocal, setUsernameLocal] = useState(null);
  const [rolesLocal, setRolesLocal] = useState([]);
  const [headerVisible, setHeaderVisible] = useState(true);


  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await api.isAuthenticated();
        setIsAuthenticatedLocal(response.data.is_authenticated);
        setUsernameLocal(response.data.username);
        const roles = response.data.roles;
        setRolesLocal(roles);
        if (roles && roles.indexOf("ADMIN") >= 0) {
          const queryParams = new URLSearchParams(window.location.search);
          const isAdminPresent = queryParams.has('admin');
          setIsAdminMode(isAdminPresent);
        }
      } catch (error) {
        console.error('Authentication check failed', error);
        setIsAuthenticatedLocal(false);
      }
    };

    checkAuthentication();
  }, []);


  if (isAuthenticatedLocal === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Router>
        {headerVisible && <Header isAuthenticatedLocal={isAuthenticatedLocal} username={usernameLocal}></Header>}
        {headerVisible}
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Projects isAdminMode={isAdminMode} filterCategory={1} isSelectImageMode={false} />} />
            <Route path="/images" element={<Projects isAdminMode={isAdminMode} filterCategory={1} isSelectImageMode={false} />} />
            <Route path="/patterns" element={<Projects isAdminMode={isAdminMode} filterCategory={2} isSelectImageMode={false} />} />
            <Route path="/pattern-from-image" element={<Projects isAdminMode={isAdminMode} filterCategory={1} isSelectImageMode={true} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/new-image" element={<ImageNewAI isAuthenticatedLocal={isAuthenticatedLocal} />} />
            <Route path="/new-pattern" element={<PatternNew />} />
            <Route path="/new-basic" element={<ProjectNew isAuthenticatedLocal={isAuthenticatedLocal} />} />
            <Route path="/from-image/:imageId" element={<ProjectFromImage isAuthenticatedLocal={isAuthenticatedLocal} />} />
            <Route path="/pattern-details/:projectId" element={<ProjectPatternDetail isAuthenticatedLocal={isAuthenticatedLocal}
              setHeaderVisible={setHeaderVisible} />} />
            <Route path="/image-details/:projectId" element={<ProjectImageDetail isAuthenticatedLocal={isAuthenticatedLocal}
              setHeaderVisible={setHeaderVisible} />} />
            <Route path="/about" element={<About />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </div>
      </Router>

    </div>
  );
}

export default App;
