import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../services/api';
import './ProjectPatternDetail.css';
import ProjectSetup from '../components/ProjectSetup';
import PatternEditor from '../components/PatternEditor';
import Login from './Login';


const ProjectPatternDetail = ({ setHeaderVisible, isAuthenticatedLocal }) => {
    let { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [instructions, setInstructions] = useState([]);
    const [currentRowIndex, setCurrentRowIndex] = useState(0);
    const [mainCurrentInstructionIndex, setMainCurrentInstructionIndex] = useState(0);
    const [setupVisible, setSetupVisible] = useState(false);
    const [imageTimestamp, setImageTimestamp] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [uniqueColors, setUniqueColors] = useState([]);
    const [advanceInstructionCount, setAdvanceInstructionCount] = useState(0);
    const imageRef = useRef(null);

    const rowOverlayRef = useRef(null);
    const instructionOverlayRef = useRef(null);

    const forceReloadImage = () => {
        setImageTimestamp(new Date().getTime());
    }

    useEffect(() => {
        setHeaderVisible(!editMode);
    }, [editMode]);

    useEffect(() => {
        if (projectId) {
            fetchProjectDetails();
        } else {
            setError('No projectId specified.');
        }
        forceReloadImage();

    }, [projectId]);

    useEffect(() => {
        if (project && instructions.length > 0 && imageRef.current) {
            highlightCurrentRowAndInstruction();
        }
    }, [project, instructions, currentRowIndex, mainCurrentInstructionIndex, imageRef.current]);


    useEffect(() => {
        const timer = setTimeout(() => {
            highlightCurrentRowAndInstruction();
        }, 3000);

        return () => clearTimeout(timer);

    }, [project, instructions, currentRowIndex, mainCurrentInstructionIndex, imageRef.current]);

    const extractUniqueColors = (instructionsList) => {
        const colorSet = new Set();
        instructionsList.forEach(row => {
            row.forEach(instruction => {
                colorSet.add(instruction.color);
            });
        });
        return Array.from(colorSet);
    };

    const fetchProjectDetails = async () => {
        setLoading(true);
        try {
            const response = await api.getProjectDetails(projectId);
            setProject(response.data);
            if (response.data.instructions) {
                const loadedInstructions = response.data.instructions;
                setInstructions(loadedInstructions);

                var uniqueColors = extractUniqueColors(loadedInstructions);
                setUniqueColors(uniqueColors);

                if (response.data?.currentIndexData) {
                    setCurrentRowIndex(response.data.currentIndexData.currentRowIndex);
                    setMainCurrentInstructionIndex(response.data.currentIndexData.currentInstructionIndex);
                } else {
                    setCurrentRowIndex(0);
                    setMainCurrentInstructionIndex(0);
                }
            }
            setError('');
        } catch (error) {
            console.error('Error fetching project details:', error);
            setError('Error fetching project details. Please try again later.');
        } finally {
            setLoading(false);
        }
    };


    const safelyRenderInstruction = () => {
        try {
            if (instructions.length > 0 && currentRowIndex < instructions.length) {
                const instruction = instructions[currentRowIndex][mainCurrentInstructionIndex];
                //return `${currentRowIndex + 1}, ${mainCurrentInstructionIndex + 1}: ${instruction.color_name} ${instruction.count}`;
                return `${instruction.color_name.replaceAll("_", " ")}: ${instruction.count}`;
            }
            return '';
        } catch (error) {
            return '';
        }
    };




    const highlightCurrentRowAndInstruction = () => {
        if (!instructions) {
            return
        }
        if (!imageRef.current || !rowOverlayRef.current || !instructionOverlayRef.current) return;

        const cellHeight = imageRef.current.clientHeight / instructions.length;
        const rowWidth = imageRef.current.clientWidth;

        rowOverlayRef.current.style.height = `${cellHeight}px`;
        rowOverlayRef.current.style.top = `${currentRowIndex * cellHeight}px`;
        rowOverlayRef.current.style.display = 'block';

        const instructionCounts = instructions[currentRowIndex].map(inst => inst.count);
        const totalInstructionsCount = instructionCounts.reduce((a, b) => a + b, 0);
        const instructionWidth = rowWidth / totalInstructionsCount;
        const startInstruction = instructionCounts.slice(0, mainCurrentInstructionIndex).reduce((a, b) => a + b, 0);

        instructionOverlayRef.current.style.width = `${instructionWidth * instructionCounts[mainCurrentInstructionIndex]}px`;
        instructionOverlayRef.current.style.height = `${cellHeight}px`;
        instructionOverlayRef.current.style.top = `${1 + currentRowIndex * cellHeight}px`;
        instructionOverlayRef.current.style.left = `${startInstruction * instructionWidth}px`;
        instructionOverlayRef.current.style.display = 'block';
    };

    const advanceInstruction = (forward) => {
        setAdvanceInstructionCount(advanceInstructionCount + 1);
        try {
            const currentRow = instructions[currentRowIndex];
            const isEvenRow = (currentRowIndex) % 2 === 0;
            let final_data = {
                "currentRowIndex": currentRowIndex, "currentInstructionIndex": mainCurrentInstructionIndex
            };

            if (forward) {
                if (isEvenRow) {
                    if (final_data.currentInstructionIndex == currentRow.length - 1) {
                        moveToNextRowEnd(final_data);
                    } else {
                        final_data.currentInstructionIndex++;
                    }
                } else {
                    if (final_data.currentInstructionIndex == 0) {
                        moveToNextRowStart(final_data);
                    } else {
                        final_data.currentInstructionIndex--;
                    }
                }
            } else {
                if (isEvenRow) {
                    if (final_data.currentInstructionIndex == 0) {
                        moveToPreviousRowStart(final_data);
                    } else {
                        final_data.currentInstructionIndex--;
                    }
                } else {
                    if (final_data.currentInstructionIndex == currentRow.length - 1) {
                        moveToPreviousRowEnd(final_data);
                    } else {
                        final_data.currentInstructionIndex++;
                    }
                }
            }

            setCurrentRowIndex(final_data.currentRowIndex);
            setMainCurrentInstructionIndex(final_data.currentInstructionIndex);

            if (isAuthenticatedLocal) {
                try {
                    api.patchProject(projectId, { "currentIndexData": { currentRowIndex: final_data.currentRowIndex, currentInstructionIndex: final_data.currentInstructionIndex } });
                } catch (error) {
                    console.error('Error updating project index:', error);
                }
            }
        } catch (error) {
            setCurrentRowIndex(0);
            setMainCurrentInstructionIndex(0);
        }
    }


    const moveToNextRowEnd = (final_data) => {
        if (final_data.currentRowIndex <= instructions.length - 1) {
            final_data.currentRowIndex++;
            final_data.currentInstructionIndex = instructions[final_data.currentRowIndex].length - 1;
        }

    }

    const moveToNextRowStart = (final_data) => {
        if (final_data.currentRowIndex <= instructions.length - 1) {
            final_data.currentRowIndex++;
            final_data.currentInstructionIndex = 0;
        }
    }

    const moveToPreviousRowStart = (final_data) => {
        if (final_data.currentRowIndex > 0) {
            final_data.currentRowIndex--;
            final_data.currentInstructionIndex = 0;
        }
    }

    const moveToPreviousRowEnd = (final_data) => {
        if (final_data.currentRowIndex > 0) {
            final_data.currentRowIndex--;
            final_data.currentInstructionIndex = instructions[final_data.currentRowIndex].length - 1;
        }
    }


    if (error) {
        return <div className="alert alert-danger" role="alert">{error}</div>;
    }

    const onImageClick = (event) => {
        const { left, width } = imageRef.current.getBoundingClientRect();
        const clickX = event.clientX - left;
        if (clickX > width / 2) {
            advanceInstruction(true);
        } else {
            advanceInstruction(false);
        }
    };

    const showSetup = () => {
        setSetupVisible(!setupVisible)
    }

    const showEditPattern = () => {
        setEditMode(!editMode);
    }

    return (
        <div style={{ marginTop: editMode ? "0px" : "90px", backgroundColor: "#EEE" }}>
            {!isAuthenticatedLocal ? (
                <>
                    <div className='login_hint'>
                        <div class="alert alert-warning ml-5 mr-5 mt-5" role="alert">
                            Please login to store your progress. After login the application will store your current progress, so next time you work on a project you can start at the same position where you left. Click <a href="/#/login">here</a> to login.
                        </div>
                    </div >
                </>
            ) : <></>}

            {loading ? (
                <div style={{ marginLeft: '40px' }}>Loading...</div>
            ) : (
                <div>
                    {project ? (
                        <div>
                            {!editMode && <div className="projectHeader">
                                <span className="projectTitle">{project.project_name}</span>
                                <div>
                                    <img src='edit_pattern1.png' style={{ marginRight: '10px', width: '50px' }} onClick={showEditPattern} ></img>
                                    <img src='edit_colors.png' style={{ marginRight: '10px', width: '50px' }} onClick={showSetup}></img>
                                </div>
                            </div>}


                            {!editMode && <div id="instruction">
                                {!setupVisible && !editMode &&
                                    <div className='firstRow'>
                                        <div>
                                            <div className="instructionContainer">
                                                {safelyRenderInstruction()}
                                            </div>
                                        </div>
                                        <div className="moveButtonContainer">
                                            <button className="moveButton" onClick={() => advanceInstruction(false)}>Backward</button>
                                            <button className="moveButton" onClick={() => advanceInstruction(true)}>Forward</button>
                                        </div>

                                    </div>
                                }
                            </div>}

                            {setupVisible &&
                                <ProjectSetup projectId={projectId} instructionList={null} colorMapping={null} showSetup={showSetup} fetchProjectDetails={fetchProjectDetails} forceReloadImage={forceReloadImage} />
                            }
                            {!editMode &&
                                <div>
                                    <div id="imageContainer" ref={imageRef}>
                                        <div id="instructionOverlay" ref={instructionOverlayRef} style={{ position: 'absolute', width: '100%' }}></div>
                                        <div id="rowOverlay" ref={rowOverlayRef} style={{ position: 'absolute', width: '100%' }}></div>
                                        <img onClick={onImageClick} src={`${project.image_path}?${imageTimestamp}`} alt="Project with Cells and Borders" id="image" />

                                    </div>
                                </div>
                            }

                            {editMode &&
                                <div>
                                    <PatternEditor project={project} instructionList={instructions} showEditor={showEditPattern} uniqueColors={uniqueColors} fetchProjectDetails={fetchProjectDetails} forceReloadImage={forceReloadImage} />
                                </div>
                            }
                        </div>
                    ) : (
                        <div>Project not found</div>
                    )}
                </div>
            )
            }


        </div >



    );
};

export default ProjectPatternDetail;
