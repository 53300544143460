import React, { useEffect, useState } from 'react';
import './Projects.css';
import { api } from '../services/api';
import { useNavigate } from 'react-router-dom';



const Projects = ({ isAdminMode, filterCategory, isSelectImageMode }) => {

    const [projectsFilteredByStarAndTag, setProjectsFilteredByStarAndTag] = useState([]);
    const [projectsFilteredByStar, setProjectsFilteredByStar] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [tags, setTags] = useState([]);
    const [enabledTags, setEnabledTags] = useState([]);
    const [currentTab, setCurrentTab] = useState('all');
    const [tagsVisible, setTagsVisible] = useState(false);
    const [isSelectMode, setIsSelectMode] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const handleAdminProjectDelete = async (e, projectId) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.style.display = 'none'
        await api.deleteProject(projectId);
    }

    const handleAdminTagDelete = async (e, tagId) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.style.display = 'none'
        await api.deleteTag(tagId);
    }

    const handleSetTagsVisible = () => {
        setTagsVisible(!tagsVisible);
    }

    const handleThumbnailClick = (projectId, kind) => {
        if (kind == 2) {
            navigate(`/pattern-details/${projectId}`);
        }
        if (kind == 1) {
            navigate(`/image-details/${projectId}`);
        }
    };

    const handleStarClick = async (e, projectId) => {
        var response = null;
        try {
            e.stopPropagation();
            const project = projectsFilteredByStarAndTag.find(project => project.project_id === projectId);
            if (project.starred) {
                setAllProjects(projects => projects.map(project => project.project_id === projectId ? { ...project, starred: 0 } : project));
                if (currentTab == "starred") {
                    setAllProjects(projects => projects.filter(project => project.project_id !== projectId));
                }
                response = await api.unstarProject(projectId);
            } else {
                setAllProjects(projects => projects.map(project => project.project_id === projectId ? { ...project, starred: 1 } : project));
                response = await api.starProject(projectId);
            }
        } catch (error) {
            //alert(error);
        } finally {
            //alert(JSON.stringify(response));
        }
    }

    const handleTagClick = (tag) => {
        setEnabledTags(prevEnabledTags => {
            const tagIndex = prevEnabledTags.indexOf(tag);
            if (tagIndex === -1) {
                return [...prevEnabledTags, tag];
            } else {
                return prevEnabledTags.filter((_, index) => index !== tagIndex);
            }
        });
    };

    const handleCreateNewImage = () => {
        navigate(`/new-image`);
    }

    const handleCreateNewPattern = () => {
        navigate(`/new-pattern`);
    }


    const filterProjectsForCurrentScreen = async () => {
        const starredTabActive = currentTab === "starred";

        let filteredProjects = [];

        if (starredTabActive) {
            filteredProjects = allProjects.filter(project => project.starred === 1)
        } else {
            filteredProjects = allProjects;
        }

        if (filterCategory !== null) {
            filteredProjects = filteredProjects.filter(project => project.kind === filterCategory);
        }

        filteredProjects = filteredProjects.sort((a, b) => {
            if (a.kind !== b.kind) return a.kind - b.kind;
            if (a.public !== b.public) return a.public - b.public;
            return a.project_name.localeCompare(b.project_name);
        });

        setProjectsFilteredByStar(filteredProjects);
        filterProjectsByTag(filteredProjects);
    };

    const filterProjectsByTag = (projectsToFilter) => {
        let filteredProjects = projectsToFilter;

        if (enabledTags && projectsToFilter && enabledTags.length > 0) {
            filteredProjects = projectsToFilter.filter(project => {
                const projectTagsNormalized = project.tags.map(tag =>
                    tag.toLowerCase().replace(/[\s-_]+/g, '')
                );

                return enabledTags.every(enabledTag =>
                    projectTagsNormalized.includes(enabledTag)
                );
            });
        }

        setProjectsFilteredByStarAndTag(filteredProjects);
    };


    const fetchProjects = async () => {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const fetch_all_projects = queryParams.has('fetch_all');

            setIsLoading(true);
            let response = null;
            response = await api.getProjects(false, fetch_all_projects);

            const allProjectsFromServer = [...response.data['private'], ...response.data['public']]
            setAllProjects(allProjectsFromServer);
            setError('');
        } catch (error) {
            setError('Failed to fetch projects. Please try again later.');
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    function generateTagSummary() {
        const tags = {};

        projectsFilteredByStarAndTag.forEach(project => {
            if (project.tags && project.tags.length > 0) {
                project.tags.forEach(tag => {
                    const normalizedTag = tag.toLowerCase().replace(/[\s-_]+/g, '');
                    if (tags[normalizedTag]) {
                        tags[normalizedTag]++;
                    } else {
                        tags[normalizedTag] = 1;
                    }
                });
            }
        });
        setTags(tags);
    }


    useEffect(() => {
        fetchProjects();
    }, []);

    useEffect(() => {
        generateTagSummary();
    }, [projectsFilteredByStarAndTag]);


    useEffect(() => {
        filterProjectsForCurrentScreen();
    }, [allProjects, currentTab, filterCategory]);


    useEffect(() => {
        setEnabledTags([]);
    }, [currentTab]);


    useEffect(() => {
        filterProjectsByTag(projectsFilteredByStar);
    }, [enabledTags, projectsFilteredByStar]);


    return (
        <div id="wholeProjectsContainer">
            <div >
                <ul className="nav nav-tabs custom-tab-container">
                    <li className="nav-item">
                        <a href="#/"
                            className={`nav-link ${currentTab === 'starred' ? 'active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setCurrentTab('starred');
                            }}>
                            Starred
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#/"
                            className={`nav-link ${currentTab === 'all' ? 'active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setCurrentTab('all');
                            }}>
                            All
                            {(filterCategory == 1) && (" Images")}
                            {(filterCategory == 2) && (" Patterns")}
                        </a>
                    </li>
                </ul>


                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                {isLoading ? (
                    <p>Loading projects...</p>
                ) : (

                    <>
                        <div id="buttonsContainer">
                            <>
                                {(filterCategory == 2) && (<button className="btn btn-secondary btn_small" onClick={handleCreateNewPattern} >New Pattern</button>)}
                                {(filterCategory == 1) && (<button className="btn btn-secondary btn_small" onClick={handleCreateNewImage} >New Image</button>)}
                            </>
                            <button className="btn btn-secondary btn_small" onClick={handleSetTagsVisible}>
                                {(tagsVisible) && ("Hide tags")}
                                {(!tagsVisible) && ("Filter by tags")}
                            </button>
                        </div>

                        {(isSelectImageMode) && (<div>
                            <div class="alert alert-primary ml-5 mr-5 mt-1" role="alert">
                                Choose an image you like from below. Click on the image to open it and then click the blue button "Create pattern from image", at the top of the screen.
                            </div>
                        </div>)}

                        {(tagsVisible) && (
                            <div id="tagsContainer">
                                {Object.keys(tags).map(tag => {
                                    const normalizedTag = tag.replace(/[\s-_]+/g, '');
                                    return (<>
                                        <a
                                            key={tag}
                                            href="#/"
                                            className={`tag ${enabledTags.includes(normalizedTag) ? 'enabled' : 'disabled'}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleTagClick(normalizedTag);
                                            }}
                                        >
                                            {normalizedTag} ({tags[tag]})
                                        </a>
                                        {(isAdminMode) && (
                                            <>
                                                <a href="" style={{ marginRight: "20px" }} onClick={(e) => {
                                                    handleAdminTagDelete(e, normalizedTag);
                                                }}>X</a>


                                            </>
                                        )}
                                    </>

                                    );
                                })}
                            </div>)}



                        <div id="projectsContainer">
                            {projectsFilteredByStarAndTag && projectsFilteredByStarAndTag.length > 0 ? (
                                projectsFilteredByStarAndTag.map(project => (
                                    <>
                                        <div key={project.project_id}
                                            className={`project-item ${project.public == 1 ? '' : 'private'} ${project.kind == 1 ? 'image-class' : 'pattern-class'}`}
                                            onClick={() => handleThumbnailClick(project.project_id, project.kind)}>
                                            <span>
                                                {(isAdminMode) && (
                                                    <>
                                                        <a href="" onClick={(e) => {
                                                            e.preventDefault();
                                                            handleAdminProjectDelete(e, project.project_id)
                                                        }}>X</a>


                                                    </>
                                                )}
                                            </span>
                                            <span
                                                className={`star-icon ${project.starred ? 'starred' : 'not-starred'}`}
                                                onClick={(e) => handleStarClick(e, project.project_id)}>
                                                ★
                                            </span>

                                            {(currentTab == "starred") && (
                                                <span
                                                    className="kind-icon">
                                                    {(project.kind == 2) && (<>PATTERN</>)}
                                                    {(project.kind == 1) && (<>IMAGE</>)}
                                                </span>
                                            )}

                                            {(project.kind == 1)
                                                &&
                                                (
                                                    <img style={{ cursor: "pointer" }}
                                                        src={project.raw_small_image_path}
                                                        alt="Project thumbnail"
                                                    />
                                                )}
                                            {(project.kind == 2)
                                                &&
                                                (
                                                    <img style={{ cursor: "pointer" }}
                                                        src={project.image_path}
                                                        alt="Project thumbnail"
                                                    />
                                                )}



                                            <div className="project-header">
                                                <b>{project.project_name}</b>
                                            </div>
                                            <p>
                                                {(filterCategory == 2)
                                                    &&
                                                    (<>
                                                        {project.cell_size} cells, {project.color_count} colors
                                                    </>)}
                                            </p>

                                        </div>
                                    </>
                                ))
                            ) : (
                                <div>
                                    {currentTab === 'starred' ? (
                                        <div>
                                            <div className="alert alert-warning mt-5" role="alert">
                                                You have no starred projects. Go to "Patterns" tab and click the little star icon to select your favourite projects.
                                            </div>
                                            <img className='how_to_star' src="howtostar.png" alt="How to Star" />
                                        </div>
                                    ) : (
                                        <p>No project found</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div >
    );
};

export default Projects;
