import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { api } from '../services/api';
import './Header.css';

const Header = ({ username, isAuthenticatedLocal, userRoles }) => {
  const navbarRef = useRef(null);

  const handleLogout = async (e) => {
    e.preventDefault();
    await api.logout();
    window.location.reload();
  };

  const handleNavLinkClick = () => {
    if (navbarRef.current) {
      navbarRef.current.classList.remove('show');
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-body-tertiary fixed-top">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img height="60" src="logo-crochetcraftai-small.png" alt="CrochetCraftAI Logo" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CrochetCraftAI
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div ref={navbarRef} className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav nav-pills ms-auto">
            <NavLink className="nav-link" to="/new-pattern" onClick={handleNavLinkClick}>
              New Pattern
            </NavLink>
            <NavLink className="nav-link" to="/new-image" onClick={handleNavLinkClick}>
              New Image
            </NavLink>
            <NavLink className="nav-link" to="/" onClick={handleNavLinkClick}>
              Images
            </NavLink>
            <NavLink className="nav-link" to="/patterns" onClick={handleNavLinkClick}>
              Patterns
            </NavLink>
            <NavLink className="nav-link disabled" to="/details" onClick={handleNavLinkClick}>
              Instructions
            </NavLink>
            <NavLink className="nav-link" to="/about" onClick={handleNavLinkClick}>
              About
            </NavLink>

            {isAuthenticatedLocal && (
              <>
                <NavLink
                  style={{ margin: '0px', paddingRight: '0px', paddingLeft: '0px' }}
                  className="btn btn-link"
                  to="/profile"
                  onClick={handleNavLinkClick}
                >
                  {username}
                </NavLink>
                <NavLink
                  onClick={handleLogout}
                  style={{ margin: '0px', paddingRight: '0px' }}
                  className="btn btn-link"
                  to="#"
                >
                  Logout
                </NavLink>
              </>
            )}
            {!isAuthenticatedLocal && (
              <>
                <NavLink
                  style={{ margin: '0px', paddingRight: '0px', paddingLeft: '0px' }}
                  className="btn btn-link"
                  to="/login"
                  onClick={handleNavLinkClick}
                >
                  Login
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;