import { default as React, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './PatternNew.css';


const PatternNew = () => {

    const navigate = useNavigate();
    const handlePatternNewOptionChange = (event) => {
        if (event.target.value == "create_new_image") {
            navigate(`/new-image`);
        }
        if (event.target.value == "use_existing_image") {
            navigate(`/pattern-from-image`);
        }
    }


    const selectPatternGenerationType = () => {
        return (

            <>
                <div >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10">

                                <div className='card' id="mainDiv">
                                    <h5 className="card-title">How do you want to create the pattern?</h5>

                                    <div className="card-body">
                                        <form>
                                            <div className="mb-3">
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="imageCreationType"
                                                    id="uploadImage"
                                                    autoComplete='off'
                                                    value="create_new_image"
                                                    onChange={handlePatternNewOptionChange}
                                                />
                                                <label className="btn btn-light  imgSourceClass" htmlFor="uploadImage">
                                                    <img className="choiceImg" src='pattern-from-upload.jpeg' alt="Create Image via AI" style={{ marginRight: "20px", verticalAlign: "middle", }}></img>
                                                    I want to create or upload my own image first and then create a pattern based on that image
                                                </label>

                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="imageCreationType"
                                                    id="describeImage"
                                                    autoComplete='off'
                                                    value="use_existing_image"
                                                    onChange={handlePatternNewOptionChange}
                                                />
                                                <label className="btn btn-light imgSourceClass" htmlFor="describeImage">
                                                    <img className="choiceImg" src='pattern-from-existing.jpeg' alt="Create Image via AI" style={{ marginRight: "20px", verticalAlign: "middle", }}></img>
                                                    I want to create a pattern based on an existing public image
                                                </label>


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div></div></div>
            </>
        )
    }

    return selectPatternGenerationType();

};

export default PatternNew;
