import { default as React, useState } from 'react';
import './ProjectNew.css';
import { api } from '../services/api';
import Login from './Login';

const StartNewProject = (isAuthenticatedLocal) => {
    const [projectName, setProjectName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [numberOfColors, setNumberOfColors] = useState('3');
    const [numberOfCells, setNumberOfCells] = useState('60');
    const [isUploading, setIsUploading] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleColorChange = (event) => {
        setNumberOfColors(event.target.value);
    };

    const handleCellChange = (event) => {
        setNumberOfCells(event.target.value);
    };

    const handleUpload = async () => {
        try {
            setIsUploading(true);
            setNotificationMessage('Uploading file, please wait...');

            const signedUrlResponse = await api.getSignedUrl();
            const { url: uploadUrl, id: uniqueId, s3_file_url } = signedUrlResponse.data;

            const uploadProgress = (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log(`Uploading... ${percentCompleted}%`);
            };
            await api.uploadFileToS3(uploadUrl, selectedFile, uploadProgress);

            const projectData = {
                unique_id: uniqueId,
                project_name: projectName,
                cell_size: numberOfCells,
                color_count: numberOfColors,
                s3_file_url,
            };
            await api.createProject(projectData);

            setNotificationMessage('Project created successfully!');
        } catch (error) {
            console.error('Error during the upload process', error);
            setNotificationMessage('Error during the upload process');
        } finally {
            setIsUploading(false);
        }
    };


    const mustAuthenticateHint = () => (
        <div className='login_hint'>
            <div class="alert alert-warning ml-5 mr-5 mt-5" role="alert">
                To create a new project you have to login first.
                This is needed so that we can associate the new project with your account.
            </div>
            <Login></Login>
        </div >
    )


    const authenticatedContent = () => (

        <div className="container mt-5">
            <div className="row">
                <div className="col-md-10">
                    <div className="card">
                        <div className="card-body">
                            {/* Project Name Input */}
                            <div className="form-group mb-3">
                                <label htmlFor="projectName">Project Name</label>
                                <input
                                    type="text"
                                    id="projectName"
                                    className="form-control"
                                    placeholder="Enter project name"
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                />
                            </div>

                            {/* File Input */}
                            <div className="mb-3">
                                <label htmlFor="formFile" className="form-label">Input file</label>
                                <input className="form-control" type="file" onChange={handleFileChange} id="formFile" disabled={isUploading} />
                            </div>

                            {/* Colors and Cells Selection */}
                            <div className="row mb-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="colorsCombo">How many colors?</label>
                                    <select className="form-select" id="colorsCombo" onChange={handleColorChange} disabled={isUploading}>
                                        <option>2</option>
                                        <option selected>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>8</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="cellsCombo">How many cells?</label>
                                    <select className="form-select" id="cellsCombo" onChange={handleCellChange} disabled={isUploading}>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                        <option selected>60</option>
                                        <option>70</option>
                                        <option>80</option>
                                        <option>90</option>
                                    </select>
                                </div>
                            </div>

                            {/* Upload Button */}
                            <div className="text-end">
                                <button className="btn btn-primary" onClick={handleUpload} disabled={isUploading}>
                                    {isUploading ? 'Processing, please wait...' : 'Upload'}
                                </button>
                            </div>

                            {/* Notification Area */}
                            {notificationMessage && (
                                <div className="alert alert-info mt-3">
                                    {notificationMessage}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    if (isAuthenticatedLocal.isAuthenticatedLocal) {
        return authenticatedContent();
    } else {
        return mustAuthenticateHint();
    }
};

export default StartNewProject;
