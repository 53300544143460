import React, { useState, useEffect, useRef } from 'react';

import { api } from '../services/api';
import "./PatternEditor.css";

const hasFinePointer = window.matchMedia("(pointer: fine)").matches;


const PatternEditor = ({ project, instructionList, showEditor, fetchProjectDetails, forceReloadImage }) => {
    const imageEditRef = useRef(null);

    const PENCIL_TOOL = 0;
    const BUCKET_TOOL = 1;
    const [colorMatrix, setColorMatrix] = useState(null);
    const [uniqueColors, setUniqueColors] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedTool, setSelectedTool] = useState(PENCIL_TOOL);
    const [selectedColorButton, setSelectedColorButton] = useState(null);
    const [undoStack, setUndoStack] = useState([]);
    const [zoomFactor, setZoomFactor] = useState(1);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showReplaceDialog, setShowReplaceDialog] = useState(false);
    const [selectedLeftColor, setSelectedLeftColor] = useState(null);
    const [selectedRightColor, setSelectedRightColor] = useState(null);
    const [isColorPicking, setIsColorPicking] = useState(false);


    const selectedColorRef = useRef(null);
    const selectedToolRef = useRef(null);
    const selectedLeftColorRef = useRef(null);
    const isColorPickingRef = useRef(null);




    const predefinedNewColors = ['#000000', '#0000CC', '#0000FF', '#003300', '#006600',
        '#009900', '#00CC00', '#00FF00', '#330000', '#333300',
        '#336600', '#339900', '#33CC00', '#33FF00', '#660000',
        '#663300', '#666600', '#669900', '#66CC00', '#66FF00',
        '#990000', '#993300', '#996600', '#999900', '#99CC00',
        '#99FF00', '#CC0000', '#CC3300', '#CC6600', '#CC9900',
        '#CCCC00', '#CCFF00', '#FF0000', '#FF3300', '#FF6600',
        '#FF9900', '#FFCC00', '#FFFF00', '#000033', '#000066',
        '#003366', '#006699', '#0099CC', '#00CCFF', '#00FFFF',
        '#330033', '#660066', '#990099', '#CC00CC', '#FF00FF',
        '#333366', '#666699', '#9999CC', '#CCCCFF', '#CCFFFF',
        '#FF66CC', '#FF99CC', '#FFCCCC', '#FFFFFF']





    const createColorMatrix = (instructionList) => {
        if (!instructionList) {
            return;
        }
        const matrix = instructionList.map(row => {
            let rowColors = [];
            row.forEach(entry => {
                for (let i = 0; i < entry.count; i++) {
                    rowColors.push(entry.color);
                }
            });
            return rowColors;
        });
        return matrix;
    }

    const extractUniqueColors = (instructionsList) => {
        if (!instructionsList) {
            return;
        }
        const colorSet = new Set();
        const colorNameSet = new Set();
        instructionsList.forEach(row => {
            row.forEach(instruction => {
                if (!colorNameSet.has(instruction.color)) {
                    colorNameSet.add(instruction.color)
                    colorSet.add(hexToRgb(instruction.color));
                }
            });
        });
        var uniqueColors = Array.from(colorSet)
        setUniqueColors(uniqueColors);
    };


    const floodFill = (grid, startRow, startCol) => {
        const targetColor = grid[startRow][startCol];
        const result = [];
        const visited = new Set();

        function fill(row, col) {
            if (row < 0 || row >= grid.length || col < 0 || col >= grid[row].length) {
                return;
            }
            const pos = `${row},${col}`;
            if (visited.has(pos) || grid[row][col] !== targetColor) {
                return;
            }
            visited.add(pos);
            result.push({ row: row, cell: col });
            fill(row - 1, col);
            fill(row + 1, col);
            fill(row, col - 1);
            fill(row, col + 1);
        }
        fill(startRow, startCol);
        return result;
    }


    const computeSelectedCells = (rowIndex, cellIndex) => {
        if (selectedToolRef.current == PENCIL_TOOL) {
            return [{ row: rowIndex, cell: cellIndex }];
        }
        if (selectedToolRef.current == BUCKET_TOOL) {
            var newSelectedCells = floodFill(colorMatrix, rowIndex, cellIndex);
            return newSelectedCells;
        }
    }

    function hexToRgb(hex) {
        const validHex = hex.replace(/^#/, '');
        const integer = parseInt(validHex, 16);
        const r = (integer >> 16) & 255;
        const g = (integer >> 8) & 255;
        const b = integer & 255;
        return [r, g, b];
    }

    function rgbToHex(rgb) {
        const [r, g, b] = rgb;
        const toHex = (color) => {
            const clampedValue = Math.max(0, Math.min(255, color));
            const hex = clampedValue.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };
        return '#' + toHex(r) + toHex(g) + toHex(b);
    }

    const renderColorMatrix = () => {
        if (!colorMatrix) {
            return;
        }
        console.log("rendering color matrix");
        const container = imageEditRef.current;
        const countWidth = colorMatrix[0].length;
        const countHeight = colorMatrix.length;
        const windowWidth = window.innerWidth * 0.99 * 0.98 - 40;
        var cellHeight = zoomFactor * ((windowWidth / countWidth)) + 'px';
        const screenWidth = window.innerWidth;
        const firstTime = container.innerHTML.length < 500;
        if (firstTime) {
            container.innerHTML = '';
        }
        colorMatrix.forEach((row, rowIndex) => {

            var rowDiv = null;
            if (firstTime) {
                rowDiv = document.createElement('div');
                rowDiv.id = `row_${rowIndex}`;
                rowDiv.className = 'matrix-row';
            } else {
                rowDiv = document.getElementById(`row_${rowIndex}`);
            }

            rowDiv.style.width = `${screenWidth * zoomFactor * 0.99}px`;
            row.forEach((color, cellIndex) => {
                var colorSpan = null;
                if (firstTime) {
                    colorSpan = document.createElement('span');
                } else {
                    colorSpan = document.getElementById(`${rowIndex}_${cellIndex}`);
                }
                colorSpan.id = `${rowIndex}_${cellIndex}`;
                colorSpan.className = 'matrix-color';
                colorSpan.style.backgroundColor = color;
                colorSpan.style.paddingBottom = cellHeight;
                colorSpan.onclick = () => {
                    colorClickedCells(rowIndex, cellIndex);
                };
                rowDiv.appendChild(colorSpan);
            });
            container.appendChild(rowDiv);
        });
    };

    const handleReplaceColors = () => {
        if (selectedLeftColor && selectedRightColor) {
            const newMatrix = colorMatrix.map(row => row.map(color => {
                if (color === selectedLeftColor) return selectedRightColor;
                return color;
            }));
            setUndoStack(prevStack => [...prevStack, colorMatrix]);
            setColorMatrix(newMatrix);
            setShowReplaceDialog(false);
        }
    };

    const handleAddNewColor = (color) => {
        setUniqueColors([...uniqueColors, hexToRgb(color)]);
        setShowColorPicker(false);
    };

    const handleColorPickCellClick = (rowIndex, cellIndex) => {
        const clickedColor = colorMatrix[rowIndex][cellIndex];
        setSelectedLeftColor(clickedColor);
        selectedLeftColorRef.current = clickedColor;
        setShowReplaceDialog(true);
    };




    useEffect(() => {
        if (uniqueColors[0]) {
            selectedColorRef.current = rgbToHex(uniqueColors[0]);
            setSelectedColorButton(0)
        }
    }, [uniqueColors]);

    useEffect(() => {
        selectedToolRef.current = selectedTool;
    }, [selectedTool]);


    useEffect(() => {
        if (instructionList) {
            renderColorMatrix();
        }
    }, [colorMatrix, zoomFactor]);

    useEffect(() => {
        extractUniqueColors(instructionList);
    }, [instructionList]);


    useEffect(() => {
        if (instructionList) {
            const colorMatrix = createColorMatrix(instructionList);
            setColorMatrix(colorMatrix);
        }
    }, [instructionList]);


    const colorClickedCells = (rowIndex, cellIndex) => {
        if (isColorPickingRef.current) {
            handleColorPickCellClick(rowIndex, cellIndex);
            setIsColorPicking(false);
            isColorPickingRef.current = false;
        } else {
            if (!selectedColorRef.current) {
                return;
            }
            const selectedCellsToColor = computeSelectedCells(rowIndex, cellIndex);
            const newMatrix = colorMatrix.map(row => [...row]);
            selectedCellsToColor.forEach(cell => {
                newMatrix[cell.row][cell.cell] = selectedColorRef.current;
            });
            setUndoStack(prevStack => [...prevStack, colorMatrix]);
            setColorMatrix(newMatrix);
        }
    };



    const cancelEdit = () => {
        showEditor(false);
    }

    const generateInstructionsFromColorMatrix = (colorsArray) => {
        const newInstructionList = colorsArray.map(row => {
            let result = [];
            let previousColor = null;
            let count = 0;

            row.forEach(color => {
                if (color === previousColor) {
                    count++;
                } else {
                    if (previousColor !== null) {
                        result.push({ color: previousColor, count: count });
                    }
                    previousColor = color;
                    count = 1;
                }
            });

            if (previousColor !== null) {
                result.push({ color: previousColor, count: count });
            }
            return result;
        });
        return newInstructionList;

    }

    const incZoomFactor = () => {
        if (zoomFactor < 6) {
            setZoomFactor(zoomFactor + 0.05);
        }
    }


    const decZoomFactor = () => {
        if (zoomFactor >= 0.1) {
            setZoomFactor(zoomFactor - 0.05);
        }
    }

    const saveEdit = async () => {
        const newInstructionList = generateInstructionsFromColorMatrix(colorMatrix);
        setIsSaving(true);
        await api.patchProject(project.project_id, { "instructionList": newInstructionList });
        setIsSaving(false);
        showEditor(false);
        forceReloadImage();
        fetchProjectDetails();
    }

    const getToolButtonClass = (buttonTool) => {
        return `btnTool ${selectedTool === buttonTool ? 'btnToolActive' : ''}`;
    };

    const undo = () => {
        setUndoStack((prevStack) => {
            if (prevStack.length === 0) {
                return prevStack;
            }
            const lastMatrix = prevStack[prevStack.length - 1];
            setColorMatrix([...lastMatrix]);
            return prevStack.slice(0, -1);
        });
    }


    const ReplaceColorsDialog = () => (
        <div className="replaceColorsDialog">
            <div className="replaceColorsContainer">
                <div className="colorSelection">
                    <h4>Replace this color:</h4>
                    <div className="colorGrid">
                        {uniqueColors.map((color, index) => (
                            <div
                                key={index}
                                className={`btnColorSet ${rgbToHex(color) === selectedLeftColor ? 'btnColorActive' : ''}`}
                                style={{ backgroundColor: rgbToHex(color) }}
                                onClick={() => setSelectedLeftColor(rgbToHex(color))}
                            />
                        ))}
                        <img
                            src="colorpick.jpg"
                            className='colorBtn'
                            onClick={() => {
                                setShowReplaceDialog(false);
                                setIsColorPicking(true);
                                isColorPickingRef.current = true;
                            }}
                            disabled={isSaving}
                        />
                    </div>
                </div>
                <div className="colorSelection">
                    <h4>With this color:</h4>
                    <div className="colorGrid">
                        {uniqueColors.map((color, index) => (
                            <div
                                key={index}
                                className={`btnColorSet ${rgbToHex(color) === selectedRightColor ? 'btnColorActive' : ''}`}
                                style={{ backgroundColor: rgbToHex(color) }}
                                onClick={() => setSelectedRightColor(rgbToHex(color))}
                            />
                        ))}
                        <img
                            src="addnewcolor.jpg"
                            className='colorBtn'
                            onClick={(e) => setShowColorPicker(true)}
                            disabled={isSaving}
                        />
                    </div>
                </div>
            </div>
            <div className="button-right">
                <button className="btn btn-secondary" onClick={() => setShowReplaceDialog(false)}>Cancel</button>
                <button className="btn btn-primary" onClick={handleReplaceColors}>Replace</button>
            </div>
        </div>
    );




    const ColorPickerDialog = () => (
        <div className="colorPickerDialog">
            <div className="predefinedColors">
                {predefinedNewColors.map((color, index) => (
                    <div
                        key={index}
                        className="predefinedColor"
                        style={{ background: color }}
                        onClick={() => handleAddNewColor(color)}
                    />
                ))}
            </div>
            <div className='button-right'>
                <button className="btn btn-secondary" onClick={() => setShowColorPicker(false)}>Cancel</button>
            </div>
        </div>
    );



    return (
        <div>
            <div className="button-right">
                {isSaving && <div class="alert alert-primary">
                    Regenerating image, please wait
                </div>}
                <div style={{ marginRight: "10px" }}>
                    <button className="btn btn-primary" style={{ width: "100px" }} onClick={saveEdit} disabled={isSaving}>
                        Save
                    </button>
                    <button className="btn btn-secondary" style={{ width: "100px" }} onClick={cancelEdit} disabled={isSaving}>Cancel</button>
                </div>
            </div>
            <div className="button-container-editor">
                <div>
                    <div style={{ marginBottom: "7px" }}>
                        <img src='pencil.jpg' className={getToolButtonClass(PENCIL_TOOL)}
                            onClick={(e) => setSelectedTool(PENCIL_TOOL)} disabled={isSaving}>
                        </img>
                        <img src='bucket.jpg' className={getToolButtonClass(BUCKET_TOOL)}
                            onClick={(e) => setSelectedTool(BUCKET_TOOL)} disabled={isSaving}>
                        </img>
                        <img src='undo.jpg' className='realImgBtn'
                            onClick={(e) => undo()} disabled={isSaving}>
                        </img>
                        <img src='zoomout.jpg' className='realImgBtn'
                            onClick={(e) => decZoomFactor()} disabled={isSaving}>
                        </img>
                        <img src='zoomin.jpg' onClick={(e) => incZoomFactor()} className='realImgBtn'
                            disabled={isSaving}>
                        </img>
                    </div>
                    <div>
                        {uniqueColors.map((color, index) => (
                            <button key={index} className={`btnColorSet ${index === selectedColorButton ? 'btnColorActive' : ''}`} onClick={(e) => { selectedColorRef.current = rgbToHex(color); setSelectedColorButton(index) }} style={{ background: rgbToHex(color) }} disabled={isSaving}>
                                &nbsp;
                            </button>
                        ))}

                        <img
                            src="addnewcolor.jpg"
                            className='colorBtn'
                            onClick={(e) => setShowColorPicker(true)}
                            disabled={isSaving}
                        />
                        <img
                            src="swapcolors.jpg"
                            className='colorBtn'
                            onClick={() => setShowReplaceDialog(true)}
                            disabled={isSaving}
                        />

                        {showReplaceDialog && <ReplaceColorsDialog />}
                        {showColorPicker && <ColorPickerDialog />}
                    </div>

                </div>
            </div>

            {isColorPickingRef.current && (
                <div className="alert alert-info" style={{ marginBottom: "10px", marginTop: "10px", textAlign: "center" }}>
                    Click a cell to choose the color to be replaced.
                </div>
            )}


            <div id="imageEditContainer" ref={imageEditRef}>loading...</div>
        </div>
    );
}

export default PatternEditor;
