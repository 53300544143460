import GoogleButton from 'react-google-button'
import './Login.css';
import { BACKEND_URL } from '../constants';

const Login = () => {
    const handleGoogleLogin = () => {
        window.location.href = `${BACKEND_URL}/login`;
    };
    return (
        <div>

            <div className="container mt-5" style={{ minHeight: '100vh' }}>
                <div className="row login_div">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-header">
                            </div>

                            <GoogleButton className='google-button'
                                onClick={handleGoogleLogin}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
