import React, { useState, useEffect } from 'react';

import { api } from '../services/api';
import "./ProjectSetup.css";

const ProjectSetup = ({ projectId, instructionList, colorMapping, showSetup, fetchProjectDetails, forceReloadImage }) => {
    const [isSaving, setIsSaving] = useState(false);

    const handleDelete = async () => {

        var myModal = new window.bootstrap.Modal(document.getElementById('deleteConfirmationModal'), {
            keyboard: false
        });
        myModal.show();
    };

    const performDelete = async () => {
        try {
            await api.deleteProject(projectId);
            window.location.href = '/#/projects';
        } catch (error) {
            console.error('Error deleting the project:', error);
        }
    };


    return (
        <div className='container setup'>
            <div className="button-right">
                <button className="btn btn-danger" onClick={handleDelete}>Delete Project</button>&nbsp;&nbsp;&nbsp;
            </div>


            <div className="modal fade" id="deleteConfirmationModal" tabIndex="-1" aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteConfirmationModalLabel">Confirm Deletion</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this project?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={performDelete} data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectSetup;
