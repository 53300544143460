import Header from '../components/Header';
import { api } from '../services/api';

const About = () => {

    const resetPosition = async () => {
        await api.patchProject('ac5020fa-6d29-4025-a5f0-7f088800a469', { "currentIndexData": { currentRowIndex: 0, currentInstructionIndex: 0 } });
    };
    return (
        <div>

            <div className="container mt-5" style={{ minHeight: '100vh' }}> {/* Ensure minimum screen height */}
                <div className="row">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-header">
                                <h3>About</h3>
                            </div>
                            <div className="card-body">
                                About
                            </div>
                            <button className='btn btn-main' onClick={resetPosition}>Reset Position</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
