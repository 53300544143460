import { default as React, useState, useRef, useEffect } from 'react';
import './ProjectFromImage.css';
import { api } from '../services/api';
import Login from './Login';
import { useParams } from 'react-router-dom';


const ProjectFromImage = (isAuthenticatedLocal) => {
    const STAGES = {
        IMAGE_UPLOADED: 'image:uploaded',
        PROJECT_CREATED: 'project_created',

    };
    let { imageId } = useParams();
    const [projectId, setProjectId] = useState('');
    const [projectName, setProjectName] = useState('');
    const [numberOfColors, setNumberOfColors] = useState('6');
    const [numberOfCells, setNumberOfCells] = useState('120');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [stage, setStage] = useState(STAGES.IMAGE_UPLOADED);
    const [s3FileUrl, setS3FileUrl] = useState(null);
    const [projectImageUrl, setProjectImageUrl] = useState('');
    const [progress, setProgress] = useState(0);
    const [isProgressStarted, setIsProgressStarted] = useState(false);
    const intervalRef = useRef(null);


    const handleColorChange = (event) => {
        setNumberOfColors(event.target.value);
    };
    const handleCellChange = (event) => {
        setNumberOfCells(event.target.value);
    };

    const handleSaveProject = () => {
        window.location.href = `/#/pattern-details/${projectId}`;
    }

    const startProgress = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        setProgress(0);

        setIsProgressStarted(true);
        intervalRef.current = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 99.9) {
                    clearInterval(intervalRef.current);
                    setIsProgressStarted(false);
                    return 99.9;
                }
                const increment = 0.6 * (prevProgress < 30 ? 1 : 0.1 * (100 - prevProgress));
                return prevProgress + increment;
            });
        }, 400);
    };

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isProgressStarted) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    }, [isProgressStarted]);

    useEffect(() => {
        if (imageId) {
            fetchImageProject();
        }

    }, [imageId]);


    const fetchImageProject = async () => {
        try {
            const response = await api.getProjectDetails(imageId);
            setS3FileUrl(response.data['raw_original_image_path']);
            setProjectName(response.data['project_name']);
            setNotificationMessage('');
        } catch (error) {
            console.error('Error fetching project details:', error);
            setNotificationMessage('Something went wrong. Please try again later.');
        } finally {

        }
    };


    const handleCreateProject = async () => {
        if (!projectName) {
            setNotificationMessage("Please provide a name for the project.");
            console.log("no project name");
            return;
        }
        setNotificationMessage("Creating project, please wait...")
        startProgress();

        console.log("numberOfColors", numberOfColors, "numberOfCelss", numberOfCells);
        const projectData = {
            kind: 2,
            image_id: imageId,
            project_name: projectName,
            cell_size: numberOfCells,
            color_count: numberOfColors,
        };
        const response = await api.createProject(projectData);
        const s3ImageUrl = response.data.s3_image_url;
        const projectId = response.data.unique_id;
        setProjectImageUrl(s3ImageUrl);
        setProjectId(projectId);
        setStage(STAGES.PROJECT_CREATED);
        setNotificationMessage("Project created.");
        setIsProgressStarted(false);
    }

    const handleRecreateProject = () => {
        setNotificationMessage("");
        setStage(STAGES.IMAGE_UPLOADED);
    }

    const mustAuthenticateHint = () => (
        <div className='login_hint'>
            <div class="alert alert-warning ml-5 mr-5 mt-5" role="alert">
                To create a new project you have to login first.
                This is needed so that we can associate the new project with your account.
            </div>
            <Login></Login>
        </div >
    )


    const progressBar = () => {
        return (
            <>
                {(isProgressStarted) && (
                    <>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="card">
                                        <div className="card-body" style={{}}>
                                            <div className="progress mb-4 mt-4" style={{ width: '100%', height: '10px' }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: 'blue' }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                }
            </>
        )
    }


    const patternParamsStage = () => {

        if (!s3FileUrl) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="card">
                                <div className="card-body" style={{}}>
                                    Loading...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
        }

        return (
            <div className="container">

                <div className="row">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-body">
                                {(!isProgressStarted) && (
                                    <>
                                        <div className="form-group mb-3">
                                            <label htmlFor="projectName">Project Name</label>
                                            <input
                                                type="text"
                                                id="projectName"
                                                className="form-control"
                                                placeholder="Enter project name"
                                                value={projectName}
                                                onChange={(e) => setProjectName(e.target.value)}
                                            />
                                        </div>


                                        <div className="row mb-3">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="colorsCombo">How many colors?</label>
                                                <select className="form-select" id="colorsCombo" onChange={handleColorChange} disabled={isProgressStarted} value={numberOfColors}>
                                                    <option>6</option>
                                                    <option>5</option>
                                                    <option>4</option>
                                                    <option>3</option>
                                                    <option>2</option>
                                                    <option>8</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label htmlFor="cellsCombo">How many cells?</label>
                                                <select className="form-select" id="cellsCombo" onChange={handleCellChange} disabled={isProgressStarted} value={numberOfCells}>
                                                    <option>120</option>
                                                    <option>100</option>
                                                    <option>90</option>
                                                    <option>80</option>
                                                    <option>60</option>
                                                    <option>50</option>
                                                    <option>40</option>
                                                    <option>30</option>
                                                    <option>20</option>
                                                    <option>15</option>
                                                    <option>10</option>
                                                </select>
                                            </div>
                                        </div>
                                    </>)}

                                <div className="text-end">
                                    <button className="btn btn-primary" onClick={handleCreateProject} disabled={isProgressStarted}>
                                        {isProgressStarted ? 'Processing, please wait...' : 'Create Project'}
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                {(!isProgressStarted) && (
                    <>
                        <div className="row">
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-body">
                                        <div style={{ textAlign: "center" }}>
                                            <img className="halfImage" id="imgUploaded" src={s3FileUrl} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>);
    };

    const projectCreatedStage = () => {
        const projectImageWithTimestamp = `${projectImageUrl}?${new Date().getTime()}`;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-body" style={{ textAlign: "center" }}>

                                <img className='fullProjectImage' src={projectImageWithTimestamp}></img>
                                <div id='cellsColors'>
                                    {numberOfColors} colors, {numberOfCells} cells
                                </div>
                                <div className='mt-3 text-end'>
                                    <button className="btn btn-secondary" onClick={handleRecreateProject}>
                                        I don't like it, let me try again
                                    </button>
                                    <button className="btn btn-primary" onClick={handleSaveProject}>
                                        Save Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div >
        );
    }

    const notificationMessageContent = () => {

        return (
            <>
                {progressBar()}
                {(notificationMessage) && (
                    < div className="container" >
                        <div className="row">
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-body" style={{}}>
                                        <div className="alert alert-info mt-3">
                                            {notificationMessage}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >)
                }
            </>
        );


    };

    const authenticatedContent = () => (
        <div id="mainDivNew">
            {notificationMessageContent()}
            {(stage == STAGES.IMAGE_UPLOADED) && patternParamsStage()}
            {(stage == STAGES.PROJECT_CREATED) && projectCreatedStage()}
        </div>
    )


    if (isAuthenticatedLocal.isAuthenticatedLocal) {
        return authenticatedContent();
    } else {
        return authenticatedContent();
        return mustAuthenticateHint();
    }
};

export default ProjectFromImage;
